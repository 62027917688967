import { FC, SVGProps } from "react";
import { OnTrackStatus } from "@practice/sdk";
import { Channel, ConnectionOpen } from "stream-chat";

import { FeatureNames } from "@lib/constants/featureNames";

import { SelectTimeTypeOptionValueType } from "@components/Form/SelectTimeTypeOption";
import { PublicPageColorType } from "@components/PublicProfile/types";

import { AccountType } from "./data/schemas/account";
import { AppointmentOutcomeType } from "./data/schemas/appointment";
import { ClientType } from "./data/schemas/client";
import { NoteType as NoteSchemaType } from "./data/schemas/note";

/**
 * Utils
 * */
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type FirestoreDocumentData = firebase.firestore.DocumentData;
export type FirestoreDocType =
  firebase.firestore.DocumentSnapshot<FirestoreDocumentData>;

export type DocSnap =
  firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

export type DocRef =
  FirebaseFirestore.DocumentReference<FirebaseFirestore.DocumentData>;

export type SVGIconProps = SVGProps<SVGSVGElement>;

export type IconType = FC<SVGIconProps>;

export type LayoutVariantType =
  | "main"
  | "details"
  | "record"
  | "form"
  | "default";

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>;

/**
 * Artifacts
 * */

// User
export type ColorType = {
  background: string;
  foreground: string;
  accent: string;
};

export type PersonType = ClientType | AccountType;

export type Testimonial = {
  name: string;
  text: string;
  clientId?: string;
};

export interface UserType {
  id: string;
  firstName?: string;
  lastName?: string;
  avatarURL?: string;
  color?: ColorType;
  isDynamicTheme?: boolean;
  font?: string;
  email: string;
  gcal?: any; // @TODO: fix types
  slug?: string;
  timeZone: string;
  customFields?: CustomField[];
  socialLinks?: { twitter?: string; instagram?: string; linkedin?: string };
  testimonials?: Testimonial[];
  // @TODO: maybe we can get this type from the library
  stripe: {
    stripe_user_id: string;
  };
  createdAt: FirebaseDateTimeType;
  updatedAt: FirebaseDateTimeType;
  showLoginButton?: boolean;
  domains?: {
    domainName: string;
    rootDomainName: string;
    subdomainName: string;
    useCustomDomain: boolean;
  };
  featureNames?: { [FeatureName in keyof typeof FeatureNames]?: boolean };
}

export type ContactFormInfoType = {
  firstName: string;
  lastName: string;
  email: string;
  answers?: string[];
};

export type MetaInfoType = {
  metaHeaderImage?: string;
  metaDescription?: string;
};

export type SharedLibraryType = {
  status: string;
  sharedWith?: string[];
  sharedWithData?: Record<string, { sharedWithDate: FirebaseDateTimeType }>;
  path?: string;
  createdAt?: FirebaseDateTimeType;
  updatedAt?: FirebaseDateTimeType;
  broadcasted?: {
    date: Date;
  };
};

export type FolderType = {
  title: string;
} & SharedLibraryType;

export type ClientFileType = {
  formId?: string;
  clientId: string;
  path?: string;
} & FileType &
  SharedLibraryType;

export interface FileType {
  url: string;
  fileName: string;
  size: number;
  contentType: string;
  fullPath?: string;
  bucket?: string;
  extension?: string;
}

export enum CustomFieldType {
  Text = "text",
  Date = "mm-dd",
  Link = "link",
  Number = "number",
  Testimonial = "testimonial",
  Choice = "choice",
  DateWithYear = "mm-dd-yyyy",
}

export const COLLECTION_MAP = {
  file: "files",
  folder: "folders",
  note: "notes",
  link: "links",
};

export type CollectionType = keyof typeof COLLECTION_MAP;

export type CustomField = {
  field: string;
  required: boolean;
  type: CustomFieldType;
  icon: string;
};

export interface SanitizedUserType {
  id: string;
  email: string;
  avatarURL?: string;
  firstName?: string;
  lastName?: string;
  redirectUserId?: string;
  stripe?: string;
  title?: string;
  bio?: string;
  website?: string;
  coachLocation?: string;
  slug: string;
  updatedAt: FirebaseDateTimeType;
  socialLinks?: any;
  testimonials?: any[];
  pronouns?: string;
  isDynamicTheme?: boolean;
  color?: PublicPageColorType;
  font?: any;
  showCompanyLogo?: boolean;
  companyName?: string;
  companyLogo?: any;
  showLoginButton?: boolean;
  domains?: {
    domainName: string;
    rootDomainName: string;
    subdomainName: string;
    useCustomDomain: boolean;
  };
  featureNames?: { [FeatureName in keyof typeof FeatureNames]?: boolean };
}

// Appointment
export type ApptStatusType =
  | "confirmed"
  | "cancelled"
  | "declined"
  | "maybe"
  | "pending"
  | "deleted"
  | "shared"
  | "tentative"
  | "accepted"
  | "needsAction";

export type AttendeesStatusType =
  | "accepted"
  | "tentative"
  | "declined"
  | "needsAction"
  | "shared";

export interface AttendeeType {
  email: string;
  responseStatus: AttendeesStatusType;
}

export type FirebaseDateTimeType = firebase.firestore.Timestamp;

type FormReminderType = {
  formId?: string;
};

type DashboardType = {
  payments?: "dismissed";
};

export type ApptLocationTypes =
  | "google-call"
  | "zoom-call"
  | "video-call"
  | "voice-call"
  | "outgoing-call"
  | "in-person";

export interface AppointmentEventDataType {
  icon: string;
  attendeesLimit: string;
  description?: string;
  productId?: string;
  smartActions?: SmartActionType[];
}

export type AppointmentType = {
  id: string;
  title: string;
  start: FirebaseDateTimeType;
  end: FirebaseDateTimeType;
  status: ApptStatusType;
  paymentId?: string;
  packageInstanceId?: string;
  formId?: string;
  formAfter?: FormReminderType;
  formBefore?: FormReminderType;
  gcal: any;
  contactId?: string;
  contactIds?: string;
  locationType: ApptLocationTypes;
  repeat: any;
  ISOstart?: string;
  ISOend?: string;
  dashboard?: DashboardType;
  createdAt: FirebaseDateTimeType;
  noteIds?: string[];
  slug?: string;
  location?: string;
  gcalCalendar: any; // @TOO: fix type
  googleAccountId: string;
  eventData?: AppointmentEventDataType;
  groupId?: string;
  isEvent?: boolean;
  recurring?: boolean;
  outcome?: AppointmentOutcomeType;
};

export type ClientTypeCustomField = {
  value: any;
  field: string;
  formId: string;
};

export type ClientColorType = {
  background: string;
  foreground: string;
};

// Forms
export type FormStatusType =
  | "draft"
  | "scheduled"
  | "pending"
  | "shared"
  | "viewed"
  | "deleted"
  | "submitted";
export type FormType = {
  id: string;
  title: string;
  appointmentId?: string;
  coachUserId: string;
  createdAt: FirebaseDateTimeType;
  updatedAt: FirebaseDateTimeType;
  formTemplateId?: string;
  status: FormStatusType;
  items: any;
};

// Invoices
export type TaxRateType = {
  name: string;
  percentage: number;
};

// @TODO: complete type from stripe
export type StripeInvoiceType = {
  id: string;
  invoice_pdf?: string;
  client_secret?: string;
  number: string;
  hosted_invoice_url: string;
};

export type InvoiceStatusType =
  | "open"
  | "draft"
  | "shared"
  | "viewed"
  | "paid"
  | "void"
  | "failed"
  | "scheduled"
  | "processing"
  | "in-review"
  | "review-needed"
  | "pre-approved"
  | "approved-auto-charge"
  | "approved-manual"
  | "rollover"
  | "refunded"
  | "partially_refunded";

export type InvoiceType = {
  id: string;
  title: string;
  contactId: string;
  createdAt: FirebaseDateTimeType;
  currency: string;
  invoice?: StripeInvoiceType;
  paymentId?: string;
  productId?: string;
  status: InvoiceStatusType;
  total: number;
  memo: string;
  dueDate?: FirebaseDateTimeType;
  sendAt?: FirebaseDateTimeType;
  wasScheduled?: boolean;
  payeeId?: string;
  recurring?: { interval: string };
};

export type PaymentType = {
  invoice?: StripeInvoiceType;
  total: number;
  tax: number;
  currency: string;
  amount: number;
  contactId: string;
  createdAt: FirebaseDateTimeType;
};

export interface TaxType {
  id: string;
  name: string;
  percentage: number;
  inclusive?: boolean;
}

// Library
// @TODO: maybe is better create separate types: FileType and LinkType
export type LibraryStatusType = "private" | "primary" | "shared" | "deleted";
export type LibraryType = {
  id: string;
  status: LibraryStatusType;
  title?: string;
  name?: string;
  fileName?: string;
  websiteTitle?: string;
  imageUrl?: string;
  domain?: string;
  url?: string;
  path?: string;
  createdAt: FirebaseDateTimeType;
};

// Note
export type NoteStatusType = "shared" | "deleted" | "private";
export type NoteType = Omit<NoteSchemaType, "createdAt" | "updatedAt"> & {
  createdAt?: FirebaseDateTimeType;
  updatedAt?: FirebaseDateTimeType | Date;
};

export type EmailIntegration = {
  email: string;
  historyId: string;
  watchExpiration: number;
  token: {
    access_token: string;
    expiry_date: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
  };
};

export type EmailThreadType = "read" | "unread" | "sent" | "draft";
export type EmailThread = {
  id: string;
  content: string; // html
  hasUnread: boolean;
  messages: EmailMessage[];
  date: FirebaseDateTimeType;
  subject: string;
  clientIds: string[];
  coachEmail: string;
  hasContentHidden: boolean;
  status: EmailThreadType;
  inTrash: boolean;
  unreadMessageCount: number;
  groupId?: string;
};

export type EmailMessage = {
  id: string;
  content?: string;
  date: FirebaseDateTimeType;
  fromIds: string[];
  toIds: string[];
  labelIds: string[];
  subject: string;
  from: string[];
  to: string[];
  inReplyTo: string;
  messageId: string;
  contentHidden: boolean;
  bcc: string[];
  cc: string[];
};

// feed
export type FeedTypes =
  | "task"
  | "appointment"
  | "form"
  | "invoice"
  | "file"
  | "link";
export type FeedType = {
  id: string;
  actionId: string;
  clientId: string;
  createdAt: FirebaseDateTimeType;
  modifierId: string;
  name: string;
  resourceId: string;
  type: FeedTypes;
  diff: any; // @TODO: find a good way to type this field
};

// labels
export type LabelType = {
  id: string;
  title: string;
  status: "active" | "inactive";
  color: string;
  createdAt: FirebaseDateTimeType;
};

// smart actions
export type SmartActionEventTimingTypes =
  | "immediately"
  | "before"
  | "after"
  | "onDate";
export type SmartActionEventTriggerTypes =
  | "appointment-is-booked"
  | "appointment-starts"
  | "appointment-ends"
  | "form-is-submitted"
  | "payment-is-completed"
  | "package-is-booked"
  | "package-is-assigned"
  | "package-sessions-completed"
  | "send-form-reminder"
  | "product-is-purchased"
  | "package-instance-is-created"
  | "appointment-outcome-updated";
export type SmartActionActionTypes =
  | "add-label"
  | "send-form"
  | "show-form"
  | "send-reminder"
  | "send-library-item"
  | "bill-scheduled-invoice"
  | "recurring-package-instance";
export type SmartActionActionMethodTypes =
  | "to-client-record"
  | "via-email"
  | "during-book-flow"
  | "via-sms"
  | "via-email-via-sms"
  | "via-sms-via-email";
export type SmartActionActionArtifactTypes =
  | "labels"
  | "forms"
  | "files"
  | "folders"
  | "links"
  | "packages";

export type SmartActionWhenType = {
  value: number;
  type: SelectTimeTypeOptionValueType;
};

export interface SmartActionType {
  id?: string; // @TODO: the form add an id. Check if it is possible to save it
  event: {
    timming: SmartActionEventTimingTypes;
    trigger: SmartActionEventTriggerTypes;
    when?: SmartActionWhenType;
    type: "appointment" | "file" | "link" | "folder" | "invoice";
  };
  action: {
    type: SmartActionActionTypes;
    method?: SmartActionActionMethodTypes;
    artifactType: SmartActionActionArtifactTypes;
    resourceId: string;
  };
}

export type SmartActionDataStatusTypes =
  | "pending"
  | "completed"
  | "proccessing"
  | "deleted"
  | "failed";
export interface SmartActionDataType {
  id: string;
  clientId: string;
  coachId: string;
  status: SmartActionDataStatusTypes;
  when: FirebaseDateTimeType;
  createdAt: FirebaseDateTimeType;
  updatedAt: FirebaseDateTimeType;
  event: {
    timming: SmartActionEventTimingTypes;
    trigger: SmartActionEventTriggerTypes;
    resourceId: string;
    type: "appointment" | "form" | "packageInstance";
  };
  action: {
    type: SmartActionActionTypes;
    artifactType: SmartActionActionArtifactTypes;
    resourceId: string;
    method: SmartActionActionMethodTypes;
  };
}

// @deprecated: use the `OpenHoursType` imported from schema folder
export type OpenHoursType = {
  startTime: string;
  endTime: string;
  weekdays: {
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
  };
};

// @deprecated: use the `AvailabilityBlockType` imported from schema folder
export type AvailabilityType = {
  id: string;
  timezone: string;
  readCalendars: any[];
  writeCalendar: any;
  title: string;
  openHours: OpenHoursType[];
  createdAt: FirebaseDateTimeType;
};

// Groups
export type GroupMemberType = {
  clientId: string;
  addedAt: FirebaseDateTimeType;
  removedAt?: FirebaseDateTimeType;
};

export type GroupType = {
  id: string;
  title: string;
  members: Array<GroupMemberType>;
  icon: string;
  status: string;
  createdAt: FirebaseDateTimeType;
  updatedAt: FirebaseDateTimeType;
  labels?: Array<string>;
  color: { foreground: string; background: string };
};

export type ArtifactType =
  | "appointment"
  | "availability"
  | "event"
  | "client"
  | "note"
  | "invoice"
  | "form"
  | "link"
  | "file"
  | "custom field"
  | "draft";

// Keys used on the messaging page
export type ArtifactKeyTypes =
  | "appointments"
  | "forms"
  | "files"
  | "links"
  | "folders"
  | "payments"
  | "notes";

export type MoveItemsType = {
  items: LibraryItemMoveInfo[];
  to?: string;
  from?: string;
};

export type LibraryItemType = "folders" | "files" | "links";

// @TODO: move it to library models?
type LibraryItemMoveInfo = {
  id: string;
  type: LibraryItemType;
};

export type TodoColorType = "action" | "grey" | "messaging";

export interface SchedulerType {
  id: string;
  title: string;
  slug: string;
  timezone: string;
  status: "active" | "inactive" | "deleted";
  smartActions: SmartActionType[];
  messagingInvite: boolean;
  icon: string;
  duration: number;
  description?: string;
  communication: { value: string }[];
  availabilityId?: string;
  sharedWith?: string[];
}

export type TimelineItemType = {
  id?: string;
  __type: string;
  startDate?: FirebaseDateTimeType;
  submittedAt?: FirebaseDateTimeType;
  start?: FirebaseDateTimeType;
  archivedAt?: FirebaseDateTimeType;
  addedAt?: FirebaseDateTimeType;
  createdAt?: FirebaseDateTimeType;
  date?: FirebaseDateTimeType;
  allDayEvent?: boolean;
  sharedWithData?: any;
  recurring?: boolean;
  status?: string;
  invoice?: InvoiceType;
  packageInstanceId?: string;
  hidden?: boolean;
  trackingStatus?: OnTrackStatus;
};

/**
 * Stream chat local types
 * */
export type SCAttachmentType = Record<string, unknown>;
export type SCChannelType = Record<string, unknown>;
export type SCCommandType = string;
export type SCEventType = Record<string, unknown>;
export type SCMessageType = Record<string, unknown>;
export type SCReactionType = Record<string, unknown>;
export type SCUserType = Record<string, unknown>;
export type StreamChatGenerics = {
  attachmentType: SCAttachmentType;
  channelType: SCChannelType;
  commandType: SCCommandType;
  eventType: SCEventType;
  messageType: SCMessageType;
  reactionType: SCReactionType;
  userType: SCUserType;
};

export type ChannelType = Channel<StreamChatGenerics>;
export type ConnectionType = ConnectionOpen<StreamChatGenerics>;
